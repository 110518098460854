var app;
(function (app) {
    var media;
    (function (media) {
        var gallery;
        (function (gallery) {
            'use strict';
            var MediaGalleryController = /** @class */ (function () {
                /* @ngInject */
                MediaGalleryController.$inject = ["$scope", "$element", "$document", "$timeout", "$q", "$stateParams", "dataservice", "$state", "$location", "$rootScope", "rxData"];
                function MediaGalleryController($scope, $element, $document, $timeout, $q, $stateParams, dataservice, $state, $location, $rootScope, rxData) {
                    var _this = this;
                    this.$scope = $scope;
                    this.$element = $element;
                    this.$document = $document;
                    this.$timeout = $timeout;
                    this.$q = $q;
                    this.$stateParams = $stateParams;
                    this.dataservice = dataservice;
                    this.$state = $state;
                    this.$location = $location;
                    this.$rootScope = $rootScope;
                    this.rxData = rxData;
                    this.$onInit = function () {
                        rxData.rootScopeOnready().then(function (data) {
                            _this.galleryPage = _this.$stateParams.gallery;
                            // minor issue fixed with this implementation
                            if (data[_this.galleryPage] == undefined) {
                                _this.pageOBJ = data.gallery[_this.galleryPage];
                            }
                            else {
                                _this.pageOBJ = data[_this.galleryPage];
                                _this.pageOBJ.img_d = data.img_d;
                            }
                            _this.pageOBJ.hero = data.hero;
                            console.log('this.pageOBJ ', _this.pageOBJ);
                            $timeout(function () {
                                $scope.$apply();
                            });
                        }, function (err) {
                            _this.pageOBJ = false;
                            console.log('error on home', err);
                        });
                    };
                }
                ;
                MediaGalleryController.prototype.galleryInit = function () {
                    console.log('$stateParams.decission', this.$stateParams.gallery);
                    this.galleryPage = this.$stateParams.gallery;
                    console.log('hello gallery', this.galleryPage);
                };
                return MediaGalleryController;
            }());
            gallery.MediaGalleryController = MediaGalleryController;
            var MediaGalleryComponent = /** @class */ (function () {
                function MediaGalleryComponent() {
                    this.restrict = 'E';
                    this.controllerAs = 'vm';
                    this.templateUrl = 'dist/js/app.media.gallery.html';
                    this.controller = MediaGalleryController;
                }
                return MediaGalleryComponent;
            }());
            angular
                .module('app.media.gallery', []);
            angular
                .module('app.media.gallery').component('gallery', new MediaGalleryComponent());
        })(gallery = media.gallery || (media.gallery = {}));
    })(media = app.media || (app.media = {}));
})(app || (app = {}));
